/* .apply-container {
    background: #f4f4f4;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 20px auto;
  }
  
  .apply-title {
    font-size: 1.8rem;
    color: #0000FF;
    margin-bottom: 10px;
  }
  
  .apply-text {
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
  }
  
  .apply-email {
    color: #007bff;
    text-decoration: none;
    font-weight: 600;
  }
  
  .apply-email:hover {
    text-decoration: underline;
  }
  
  /* Responsive styling for smaller screens */
/* @media (max-width: 600px) {
    .apply-container {
      padding: 15px;
    }
  
    .apply-title {
      font-size: 1.5rem;
    }
  
    .apply-text {
      font-size: 0.95rem;
    }
  }  */

.apply-section {
  background-color: #f5f5f5; /* Background color behind the apply card */
  padding: 50px 0; /* Add some padding for spacing */
}

.apply-container {
  background: #ffffff; /* White background for the card */
  padding: 25px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.apply-title {
  font-size: 1.8rem;
  color: #0000ff;
  margin-bottom: 10px;
}

.apply-text {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

.apply-email {
  color: #0000ff;
  text-decoration: none;
  font-weight: 600;
}

.apply-email:hover {
  text-decoration: underline;
}

/* Responsive styling for smaller screens */
@media (max-width: 600px) {
  .apply-container {
    padding: 20px;
  }

  .apply-title {
    font-size: 1.5rem;
  }

  .apply-text {
    font-size: 0.95rem;
  }
}

.internships-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  padding: 20px;
  margin: 20px;
}

.internship-card {
  background: #ffffff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
}

.internship-card:hover {
  transform: translateY(-5px);
}

.internship-title {
  font-size: 1.5rem;
  color: #0000FF;
  margin-bottom: 10px;
  font-weight: 600;
  text-align: justify;
}

.internship-text {
  font-weight: bold;
  color: #333;
  font-size: 1rem;
}

.internship-card p {
  margin: 8px 0;
  font-size: 0.95rem;
  color: #333;
  text-align: justify;
}

.internship-card p strong {
  color: #555;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .internships-container {
    padding: 15px;
    margin: 15px;
  }

  .internship-card {
    padding: 12px;
  }

  .internship-title {
    font-size: 1.3rem;
  }

  .internship-card p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .internships-container {
    padding: 10px;
    margin: 10px;
  }

  .internship-card {
    padding: 10px;
  }

  .internship-title {
    font-size: 1.2rem;
  }

  .internship-card p {
    font-size: 0.85rem;
  }
}
.why-choose-us {
    text-align: center;
    padding: 50px 20px;
    color: black;
}

h1 {
    font-size: 36px;
    margin-bottom: 20px;
}

/* Flex container for image on left and grid on right */
.why-choose-us-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
}

.left-side {
    flex: 1;
    margin-right: 10px;
    margin-left: 10px;
    display: contents;
}

.whyimage img {
    width: 25rem;
    height: auto;
}

/* Right side: Grid of items */
.right-side {
    flex: 2;
    padding-left: 70px;
}

/* Grid Container for the 4 items */
.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.grid-item {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.grid-item:hover {
    transform: translateY(-6px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.grid-container .grid-item:first-child {
    background-color: #1a1ac6;
    color: white;
}

.grid-container .grid-item:first-child p {
    color: white;
}

.grid-container .grid-item:last-child {
    background-color: #1a1ac6;
    color: white;
}

.grid-container .grid-item:last-child p {
    color: white;
}


.grid-item h3 {
    font-size: 20px;
    margin-bottom: 10px;
}

.grid-item p {
    font-size: 16px;
    color: black;
}

/* Responsive Styles */
@media (max-width: 900px) {
    .why-choose-us-content {
        flex-direction: column;
        align-items: center;
    }

    .left-side {
        padding-right: 0;
        margin-bottom: 20px;
    }

    .right-side {
        padding-left: 0;
    }

    .grid-container {
        grid-template-columns: 1fr;
    }

    .grid-container .grid-item:nth-child(1),
    .grid-container .grid-item:nth-child(3) {
        background-color: #1a1ac6;
        color: white;
    }

    .grid-container .grid-item:nth-child(1) p,
    .grid-container .grid-item:nth-child(3) p {
        color: white;
    }

    .grid-container .grid-item:last-child {
        background-color: #fff;
        color: black;
    }

    .grid-container .grid-item:last-child p {
        color: black;
    }
}
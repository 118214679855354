  .header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 129px 9px 7px 60px;
      gap: 10px;
  }

  .header-content {
      flex: 1;
      margin-right: 20px;
  }

  .header-title {
      font-size: 2.3em;
      margin-bottom: 27px;
      line-height: 1.1;
      color: black;
  }

  .header-description {
      margin-bottom: 34px;
      line-height: 1.6;
      font-size: 14px;
      font-weight: 600;
      color: black;
      text-align: justify;
  }


  .detail {
      margin-top: 15px;
  }

  .cta-button {
      border: 1px solid #7620ff;
      background-color: #f2f0f5;
      padding: 16px 60px;
      outline: none;
      color: #587ce7;
      border-radius: 18px;
      margin-top: 15px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 2px;
  }

  .cta-button:hover {
      background-color: #6822d9;
      color: white;
  }

  @media (max-width: 768px) {
      .header-container {
          flex-direction: column;
          align-items: center;
          text-align: center;
          margin-left: -1rem;
          margin-right: 1rem;
      }

      .header-title {
          font-size: 1.9rem;
          margin-top: 2rem;
      }

      .header-description {
          font-size: 0.9rem;
      }

      .cta-button {
          font-size: 0.9rem;
      }
  }

  @media (max-width: 480px) {
      .header-title {
        font-size: 1.5rem;
      }

      .header-description {
          font-size: 0.85rem;
      }

      .cta-button {
          padding: 8px 16px;
          font-size: 0.85rem;
      }
  }

  @media (max-width: 1134px){
    .header-title {
        font-size: 2.6rem;
      }
  }

  @media (max-width: 1063px){
    .header-title {
        font-size: 2.3rem;
      }
  }
  
  @media (max-width: 995px){
    .header-title {
        font-size: 1.9rem;
      }
  }

  
  @media (max-width: 778px){
    .header-title {
        font-size: 1.7rem;
      }
  }

  @media (max-width:810px){
    .header-title {
        font-size: 1.7rem;
      }
  }




  @media  (max-width: 411px) and  (max-width:574px){
    .header-title {
        font-size: 1.7rem;
      }
    
      .header-description{
        font-size: 13px;
      }
  }

  @media (max-width:394px){
    .header-title {
        font-size: 1.2rem;
      }
    
      .header-description{
        font-size: 12px;
      }
  }



  /* @media (max-width: 360px) {
    .header-title {
      font-size: 1.5rem;
    }

} */
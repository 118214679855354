/* Base section styling */
.section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F5F5F5;
  padding: 40px 20px;
  flex-wrap: wrap;
  margin-top: 1rem;
   /* Allows wrapping for responsive design */
}

/* Container for mission content and image */
.mission-container {
  display: flex;
  max-width: 1200px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  padding: 20px;
  flex-wrap: wrap; /* Allows content to wrap on smaller screens */
}

/* Mission content styling */
.mission {
  flex: 1;
  margin: 0;
}

/* Title and content styling */
.title h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: black;
  margin-bottom: 15px;
}

.content p {
  margin-bottom: 20px;
  line-height: 1.6;
  font-size: 1rem;
  font-weight: 600;
  color: black;
  text-align: justify;
}

/* Image wrapper */
.image-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.img-section img {
  width: 100%;
  height: auto;
  max-width: 600px;
  border-radius: 6px;
}

/* Responsive Media Queries */

/* Tablets and medium screens (max-width: 768px) */
@media (max-width: 768px) {
  .mission-container {
    flex-direction: column; /* Stack content vertically */
    align-items: center; /* Center align content */
    gap: 1.5rem; /* Reduce gap between elements */
  }

  .mission {
    order: 1; /* Ensure content appears first */
    margin-bottom: 20px; /* Add margin for spacing */
  }

  .image-wrapper {
    order: 2; /* Ensure image appears after content */
  }

  .title h1 {
    text-align: center;
  }

  .content p {
    font-size: 0.95rem; /* Adjust font size for readability */
    text-align: justify;
    margin-top: 0.5rem;

  }

  .img-section img {
    max-width: 90%; /* Ensure image fits within the view */
  }
}

/* Mobile devices (max-width: 480px) */
@media (max-width: 480px) {
  .section {
    padding: 20px 10px; /* Adjust padding for smaller screens */
  }

  .mission-container {
    gap: 1rem; /* Further reduce gap for smaller screens */
    padding: 0; /* Remove padding to fit content better */
  }

  .title h1 {
    font-size: 1.8rem;
    text-align: center;
  }

  .content p {
    font-size: 0.9rem;
    text-align: justify;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .img-section img {
    max-width: 100%; /* Ensure the image fits within the screen */
    margin-bottom: 15px; /* Add margin to separate from content */
  }
}

/* .section-container { */
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center;
    text-align: center;
    max-width: 1200px;
    margin: 2rem auto;
    gap: 4rem;
    color: black;
    padding: 3rem;
    width: 100%;
}

.section-container h1{
    font-size: 3.5rem;
    margin-bottom: 20px;
    color: black;
    font-weight: 700;
}
.value-container{
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
}

.feature-list {
    list-style: none;
    padding: 0;
    margin-top: 2rem;
}

.feature-item {
    margin-bottom: 12px;
    padding: 12px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    transition: transform 0.3s ease;
    cursor: pointer;
    text-align: justify;
}

.feature-item:hover {
    transform: translateY(-4px);
}

.feature-title {
    font-weight: bold;
    color: black;
}

@media (min-width: 768px) {
    .section-container {
        flex-direction: row;
        justify-content: space-between;
    }

    .section-container img {
        flex: 1;
        margin-right: 24px;
    }

    .section-container div {
        flex: 2;
    }

    .feature-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (min-width: 1200px) {
    .section-container {
        padding: 40px;
    }

    .feature-title {
        font-size: 1.2rem;
    }

    .feature-item {
        margin-bottom: 16px;
        padding: 16px;
    }
}

@media (max-width: 480px){
    .section-container h1{
        font-size: 2rem;
    }

  .img-section img {
    max-width: 100%; 
  }
}

.value-container{
    margin-top: 2rem;
} */

.section-container {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 2rem;
    display: flex;
    flex-direction: column; /* Default column layout for mobile */
    gap: 2rem;
}

.Value-Section-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: black;
    text-align: center;
}



/* Container for text and image */
.value-container {
    display: flex;
    flex-direction: row; /* Horizontal alignment for larger screens */
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    margin-top: -3rem;
}


/* Text content */
.feature-list {
    flex: 1; /* Allows text to take up one part of the space */
    list-style: none;
    padding: 0;
    margin: 0;
}

.feature-item {
    margin-bottom: 1rem;
    padding: 1rem;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    transition: transform 0.3s ease;
    text-align: left;
    cursor: pointer;
}

.feature-item:hover {
    transform: translateY(-4px);
}

.feature-title {
    font-weight: bold;
    color: black;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .value-container {
        flex-direction: column; /* Stacks content vertically on smaller screens */
        align-items: center;
        text-align: center; /* Center text for smaller screens */
    }

    .feature-list {
        padding: 0;
    }

    .feature-item {
        padding: 0.8rem;
    }

    .Value-Section-title {
        font-size: 2.5rem;
        margin-bottom: 2rem; /* Smaller title for mobile */
    }
}

@media (max-width: 480px) {
    .Value-Section-title {
        font-size: 1.8rem; 
        margin-bottom: 3rem;/* Further reduce font size for small screens */
    }

    .feature-item {
        padding: 0.5rem;
        margin-bottom: 0.8rem;
    }
}



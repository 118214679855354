.life-container {
    padding: 40px 20px;
    margin: 0px 45px 40px 40px;
  }
  
  .life-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .life-text {
    flex: 1;
    max-width: 50%;
  }
  
  .life-text h2 {
    font-size: 2rem;
    color: #0000FF;
    margin-bottom: 15px;
  }
  
  .life-text p {
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
    text-align:justify;
  }
  
  .life-image {
    flex: 1;
    max-width: 45%;
  }
  
  .life-image img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Responsive styling for smaller screens */
  @media (max-width: 768px) {
    .life-content {
      flex-direction: column;
      text-align: center;
    }
  
    .life-text {
      max-width: 100%;
      margin-bottom: 20px;
    }
  
    .life-image {
      max-width: 100%;
    }
  
    .life-image img {
      height: auto;
    }
  }
  
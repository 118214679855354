   .profile-grid {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    padding: 30px;
    background-color: #F5F5F5;
}

.profile-card {
    flex: 1;
    min-width: 300px;
    max-width: 32%;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.profile-card:hover {
    transform: translateY(-6px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.profile-image-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
}

.profile-image {
    width: 147px;
    height: 143px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #007bff; /* Optional border */
}

.profile-content {
    text-align: center;
}

.profile-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
}

.profile-description {
    font-size: 1rem;
    color: black;
    margin-bottom: 12px;
}

.profile-quote {
    font-style: italic;
    font-size: 0.9rem;
    color: #007bff;
    margin-top: 12px;
    border-left: 4px solid #007bff;
    padding-left: 12px;
}

@media (max-width: 768px) {
    .profile-grid {
        flex-direction: column;
        align-items: center; 
        margin-top: -2rem;/* Center-aligns the profile cards in a column layout */
    }

    .profile-card {
        max-width: 90%;
        margin-bottom: 20px;
        padding: 15px; /* Adjusts padding for smaller screens */
        transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    }

    .profile-card:hover {
        transform: scale(1.02); /* Slight scale-up effect on hover for better interaction feedback */
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }

    .profile-image {
        width: 130px; /* Slightly larger profile image for better clarity */
        height: 130px;
        transition: width 0.3s ease, height 0.3s ease; /* Smooth size transition */
    }

    .profile-content {
        padding: 10px 0; /* Adds padding around content for spacing */
    }
}

@media (max-width: 1006px){
    .profile-card {
        max-width: 90%;
        /* display: flex;
        justify-content: center; */
        margin-bottom: 20px;
        padding: 15px; /* Adjusts padding for smaller screens */
        transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
        margin-left: 0.6rem;
} 
}

@media (max-width: 432px){
    .profile-card {
        margin: -2rem;
        margin-top: 1rem
}
}




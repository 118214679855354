.career-list-container {
    margin: 20px 45px 40px 58px;
}

.career-list {
    list-style: none;
    padding-left: 0;
    margin-top: 35px;
}

.career-list li {
    margin-bottom: 12px;
    padding: 10px 15px;
    display: flex;
    align-items: flex-start;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    font-size: 1rem;
}

.career-list li::before {
    content: '➤';
    color: #0000FF;
    margin-right: 10px;
    font-size: 1.2rem;
}

.career-list li:hover {
    transform: translateY(-3px);
    background: #e6f7ff;
}

.career-list .highlight-text {
    font-weight: bold;
    color: #333;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
    .career-list-container {
        margin: 20px 20px;
        /* Reduce margin on smaller screens */
    }

    /* Remove list styling on small screens but keep the background and hover effect */
    .career-list {
        padding-left: 0;
        /* Remove padding on smaller screens */
    }

    .career-list li {
        flex-direction: column;
        /* Stack content vertically for better readability */
        padding: 15px;
        margin-bottom: 10px;
        /* Reduce bottom margin */
        list-style: none;
        /* Remove bullet points */
        background: #f9f9f9;
        /* Keep background */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        /* Keep box shadow */
    }

    .career-list li::before {
        content: none;
        /* Remove bullet point */
    }

    .career-list li:hover {
        background: #e6f7ff;
        /* Keep hover effect */
    }

    .career-list h2 {
        font-size: 1.5rem;
        /* Smaller font size for heading */
        text-align: center;
        /* Center-align heading */
    }
}

@media (max-width: 480px) {
    .career-list-container {
        margin: 15px 10px;
        /* Even smaller margin for mobile */
    }

    .career-list li {
        font-size: 0.9rem;
        /* Reduce font size on small screens */
    }
}
.section-container {
  display: flex;
  flex-direction: column; /* Default column layout for mobile */
  gap: 2rem;
}

.Value-Section-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: black;
  text-align: center;
}

/* Container for text and image */
.value-container {
  display: flex;
  flex-direction: row; /* Horizontal alignment for larger screens */
  justify-content: space-between;
  align-items: center;
}

/* Text content */
.feature-list {
  flex: 1; /* Allows text to take up one part of the space */
  list-style: none;
  padding: 0;
  margin: 0;
}

.feature-item {
  margin-bottom: 1rem;
  padding: 1rem;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease;
  text-align: left;
  cursor: pointer;
}

.feature-item:hover {
  transform: translateY(-4px);
}

.feature-title {
  font-weight: bold;
  color: black;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .value-container {
    flex-direction: column; /* Stacks content vertically on smaller screens */
    align-items: center;
    text-align: center; /* Center text for smaller screens */
  }

  .feature-list {
    padding: 0;
  }

  .feature-item {
    padding: 0.8rem;
  }

  .Value-Section-title {
    font-size: 2.5rem;
    margin-bottom: 2rem; /* Smaller title for mobile */
  }
}

@media (max-width: 480px) {
  .Value-Section-title {
    font-size: 1.8rem;
    margin-bottom: 3rem; /* Further reduce font size for small screens */
  }

  .feature-item {
    padding: 0.5rem;
    margin-bottom: 0.8rem;
  }
}

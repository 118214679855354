.careers-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin: 145px 20px 0px 20px;
  }
  
  .careers-content {
    flex: 1 1 50%;
    max-width: 600px;
  }
  
  .careers-content h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    font-weight: 700;
  }
  
  .careers-content p {
    font-size: 1.1rem;
    margin-bottom: 20px;
    line-height: 1.6;
    padding-top: 0.9rem;
    text-align: justify;
  }
  
  .careers-image {
    flex: 1 1 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 400px;
  }
  
  .careers-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 768px) {
    .careers-container {
      flex-direction: column;
    }
  
    .careers-content,
    .careers-image {
      max-width: 100%;
    }
  
    .careers-content h1 {
      font-size: 1.8rem;
    }
  }

  @media (max-width: 1029px){
    .careers-content h1 {
        font-size: 2rem;
      }
    
      .careers-content p {
        font-size: 13px;
      }
  }

  
  @media (max-width: 856px){
    .careers-content h1 {
        font-size: 1.7rem;
      }
    
      .careers-content p {
        font-size: 13px;
      }
  }

  @media (max-width: 419px){
    .careers-content h1 {
        font-size: 1.7rem;
      }
    
      .careers-content p {
        font-size: 12px;
      }
  }

  @media (max-width: 349px){
    .careers-content h1 {
        font-size: 1.6rem;
      }
    
      .careers-content p {
        font-size: 12px;
      }
  }

  
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none !important;
  color: #0b093b;
}
a:hover {
  color: #0a0368;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #fff inset; /* Change the color to your own background color */
  box-shadow: transparent;
  -webkit-text-fill-color: #fff;
}
body {
  background: #fff;
  color: #0b093b;
  font-family: "Khula", sans-serif;
  overflow-x: hidden;
  font-size: 16px;
  font-weight: 400;
}
.container {
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0px 30px;
}
@media only screen and (max-width: 859px) {
  .container {
    padding: 0 15px;
  }
}
.regular {
  font-weight: 400 !important;
}
.semiBold {
  font-weight: 600 !important;
}
.extraBold {
  font-weight: 800 !important;
}

/* HELLPERS */
.textCenter {
  text-align: center;
}
.animate {
  transition: 0.3s ease;
}
.pointer {
  cursor: pointer;
}
.relative {
  position: relative;
}
.textCenter {
  text-align: center;
}
.textLeft {
  text-align: left;
}
.textRight {
  text-align: right;
}
.x100 {
  width: 100%;
}
.active {
  border-bottom: 2px solid #0a0368;
  color: #0a0368;
}
.tag {
  padding: 5px 10px;
  margin: 10px 0;
}
.shadow {
  box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
  -webkit-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
  -moz-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
}
/* FLEXBOX */
.flex {
  display: flex;
}
.flexSpaceCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flexNullCenter {
  display: flex;
  align-items: center;
}
.flexColumn {
  flex-direction: column;
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexSpaceNull {
  display: flex;
  justify-content: space-between;
}

/* FONT SIZES */
.font11 {
  font-size: 0.688rem;
}
.font12 {
  font-size: 0.75rem;
}
.font13 {
  font-size: 0.813rem;
}
.font14 {
  font-size: 0.875rem;
}
.font15 {
  font-size: 0.938rem;
}
.font18 {
  font-size: 1.125rem;
}
.font20 {
  font-size: 1.25rem;
}
.font25 {
  font-size: 1.563rem;
}
.font30 {
  font-size: 1.875rem;
}
.font40 {
  font-size: 2.5rem;
}
.font60 {
  font-size: 3.75rem;
  line-height: 4.5rem;
}
@media only screen and (max-width: 859px) {
  .font11 {
    font-size: 0.688rem;
  }
  .font12 {
    font-size: 0.688rem;
  }
  .font13 {
    font-size: 0.75rem;
  }
  .font14 {
    font-size: 0.813rem;
  }
  .font15 {
    font-size: 0.813rem;
  }
  .font18 {
    font-size: 0.938rem;
  }
  .font20 {
    font-size: 1.125rem;
  }
  .font25 {
    font-size: 1.25rem;
  }
  .font30 {
    font-size: 1.5rem;
  }
  .font40 {
    font-size: 1.875rem;
  }
  .font60 {
    font-size: 2.8rem;
  }
}
/* BORDER RADIUS */
.radius8 {
  border-radius: 1rem;
}
.radius6 {
  border-radius: 0.6rem;
}
/* COLORS */
.darkColor {
  color: #0b093b;
}
.purpleColor {
  color: #7620ff;
}
.orangeColor {
  color: #f2b300;
}
.lightColor {
  color: #f5f5f5;
}
.coralColor {
  color: #c4ffff;
}
.greenColor {
  color: #49cb86;
}
.greyColor {
  color: #707070;
}
.whiteColor {
  color: #fff;
}
/* BACKGROUNDS */
.mobilebg {
  background-color: #7a76ce;
}
.darkBg {
  background-color: #0b093b;
}
.purpleBg {
  background-color: #7620ff;
}
.orangeBg {
  background-color: #f2b300;
}
.lightBg {
  background-color: #f5f5f5;
}
.coralBg {
  background-color: #c4ffff;
}
.greenBg {
  background-color: #49cb86;
}
.greyBg {
  background-color: #707070;
}
.whiteBg {
  background-color: #fff;
}

.footer {
  background-color: #d6d6d6; /* Blue background */
  color: #000000; /* White text */
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.footer-section {
  flex: 1;
  min-width: 200px;
  margin: 10px;
}

.footer-logo {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

h3 {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 1rem;
}

.footer-section p {
  margin: 5px 0;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.see-more {
  margin-top: 10px;
  color: #90caf9; /* Light blue for links */
  cursor: pointer;
}

.footer-bottom {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 0.9rem;
  border-top: 1px solid #90caf9;
  padding-top: 10px;
}

.payment-icons img {
  height: 20px;
  margin: 0 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    text-align: center;
  }

  .footer-bottom {
    flex-direction: column;
    text-align: center;
  }
}

/* Styling for the card container */
.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  padding: 20px;
  padding-top: 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  height: 100%;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.card-container:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

/* Icon styling */
.card-icon {
  background-color: #ececff;
  padding: 15px;
  border-radius: 50%;
  position: absolute; /* Position icon absolutely */
  top: -30px; /* Moves icon above the card to overlap */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
}

.card-title {
  color: blue;
  font-size: 18px;
  margin: 10px 0 10px; /* Adjusted margin to fit with icon overlap */
}

.card-description {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

.card-button {
  background-color: #6a0dad;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.card-button:hover {
  background-color: #4a007a;
}

/* Responsive Design */
@media (max-width: 768px) {
  .card-container {
    padding: 15px;
    padding-top: 50px; /* Maintain top padding for icon overlap on smaller screens */
  }

  .card-title {
    font-size: 16px;
  }

  .card-description {
    font-size: 13px;
  }

  .card-button {
    padding: 8px 16px;
    font-size: 13px;
  }
}

.page_404 {
  padding-top: 140px;
  background: #fff;
  font-family: "Arvo", serif;
  text-align: center;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/722246/screenshots/3066818/404-page.gif);
  background-size: cover; /* Ensures the image covers the container */
  background-repeat: no-repeat; /* Shows the image only once */
  background-position: center; /* Centers the image */
  height: 500px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.contant_box_404 {
  margin-top: -20px;
  padding: 20px;
}

.contant_box_404 h3 {
  font-size: 1.8rem;
  margin-top: 20px;
}

.contant_box_404 p {
  font-size: 1rem;
  color: #666;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #3550ff;
  margin: 20px 0;
  display: inline-block;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .four_zero_four_bg {
    height: 400px;
    max-width: 100%;
  }

  .contant_box_404 h3 {
    font-size: 1.5rem;
  }

  .contant_box_404 p {
    font-size: 0.9rem;
  }

  .link_404 {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .four_zero_four_bg {
    height: 270px;
  }

  .contant_box_404 h3 {
    font-size: 1.2rem;
  }

  .contant_box_404 p {
    font-size: 0.8rem;
  }

  .link_404 {
    font-size: 0.8rem;
    padding: 6px 12px;
  }
}
